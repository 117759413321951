<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
        :is-view="false">

        <template #cell(created_at)="data">
          {{data.item.created_at|moment('Y-MM-D')}}
        </template>
        <template #cell(periode)="data">
          {{data.item.billing?data.item.billing.month:""|moment('MMMM')}} {{data.item.billing?data.item.billing.year:""|moment('Y')}}
        </template>
        <template #cell(_)="data">
          <b-button
            v-if="data.item.billing"
            class="mr-1 btn-icon"
            size="sm"
            variant="primary"
            @click="viewForm(data.item.billing)"
          >
            <feather-icon icon="FolderIcon" />
          </b-button>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {
  BCard, BButton
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BButton
  },
  data() {
    return {
      dataurl:"/checklist",
      baseroute:"billing-rent",
      title:"Checklist Tagihan",
      fields: [
        { key: 'billing.billing.number', label: 'Billing', sortable: true },
        { key: 'periode', label: 'Periode', sortable: true },
        { key: 'staff.name', label: 'Pemeriksa', sortable: true },
        { key: 'created_at', label: 'Tanggal', sortable: true }
      ],
    }
  },
  methods:{
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.billing_id} })
    }
  }
}
</script>
<style lang="">

</style>
